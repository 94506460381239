
import { defineComponent, PropType,inject, } from "vue";

import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import $ from "jquery";
import Modal from "@/components/UI/Modal";
import router from "@/router";
import axios from "@/api/axios";

export default defineComponent({
  name: "slotFooter",
  props: {
    tid: {
      type: String,
      default: "",
    },
    nclassId: {
      type: String,
      default: "0",
    },
    pid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "1",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
    viewCartText: {
      type: String,
      default: "查看购物车",
    },
     lotid: {
      type: String,
      default: "0"
    },
    tcid: {
      type: String,
      default: "0"
    },
    stock: {
      type: Number,
      default: null
    },
    checkValue:{
      type:Number,
      default:0
    }
  },
  setup(props) {
    //  add shop car
    const { addShopCar} = useShopCar();
    const userInfo = inject("userInfo") as any;

    //查看购物车
    function toViewCart() {
      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          content: '<div>登录后才可查看购物车</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        router.push({name: "ShopCar"});
      }
    }



    function handleAddCart() {
      const formData = new FormData();

      formData.append("pid", props.pid);
      formData.append("tid", props.tid);
      formData.append("lotid", props.lotid);
      formData.append("tcid", props.tcid);
      formData.append("qty", "1");
      api
        .post("/M/Server/AddToCart", formData)
        .then((res): void => {
          Toast({
            title: res.data.msg,
            type: "success",
          });
        })
        .catch((err) => {
          Toast({
            title: err.msg || "加入购物车失败",
            type: "error",
          });
        });
    }

      function collectAction() {
        const formData = new FormData();
        formData.append("id", props.pid);
        formData.append("type", props.type);
        api
                .post("/M/Server/Collection", formData, {
                  params: {
                    validate: true,
                  },
                })
                .then((res) => {
                  if (res) {
                    Toast({
                      title: res.data.msg,
                      type: "success",
                    });
                    const collectImg = $('#collectImg').attr('src')
                    $("#collect img").prop(
                            "src",
                            collectImg
                    );
                  }
                })
                .catch((err) => {
                  Toast({
                    title: err.message || "收藏失败，请联系管理员",
                    type: "error",
                  });
                });
      }

    return {
      handleAddCart,
      collectAction,
      addShopCar,
      toViewCart,
      userInfo
    };
  },
});
