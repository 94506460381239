<template>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/36b28c56331fefd0e0541943db1facc92/1"
        >
          <img src="@/assets/images/icon5-1.png" alt />
          客服
        </a>
      </li>
        <li class="footer-li">
          <a href="javascript:;" id="collect" @click="collectAction" >
            <img
              src="@/assets/images/icon6-2.png"
              v-if="!isFav"
            />
            <img
              src="@/assets/images/icon6-1.png"
              v-else
              alt=""
            />
            收藏
          </a>
        </li>

      <li
        id="add"
        :class="{
          'footer-li button2': true,
          buttonHide: viewCartText != '查看购物车',
        }"
      >
        <!--addShopCar(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number, nclassId: number)-->
        <a href="javascript:;" @click="addShopCar(pid, Number(tid), 1 , lotid,tcid, stock,nclassId,(userInfo?userInfo.id:''),checkValue)"
        >加入购物车</a
        >
      </li>
      <li
        class="footer-li button a"
        style="background-color: #b9135a; margin-right: 0"
      >
        <router-link
          :to="{ name: 'ShopCar' }"
          v-if="viewCartText == '查看购物车'"
          >{{ viewCartText }}</router-link
        >
        <a href="javascript:;" v-else @click="standarddownloadcheck">{{
          viewCartText
        }}</a>
      </li>
    </ul>
    <img src="@/assets/images/icon6-1.png" id="collectImg" v-show="false" alt="">
  </footer>
</template>

<script lang="ts">
import { defineComponent, PropType,inject, } from "vue";

import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import $ from "jquery";
import Modal from "@/components/UI/Modal";
import router from "@/router";
import axios from "@/api/axios";

export default defineComponent({
  name: "slotFooter",
  props: {
    tid: {
      type: String,
      default: "",
    },
    nclassId: {
      type: String,
      default: "0",
    },
    pid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "1",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
    viewCartText: {
      type: String,
      default: "查看购物车",
    },
     lotid: {
      type: String,
      default: "0"
    },
    tcid: {
      type: String,
      default: "0"
    },
    stock: {
      type: Number,
      default: null
    },
    checkValue:{
      type:Number,
      default:0
    }
  },
  setup(props) {
    //  add shop car
    const { addShopCar} = useShopCar();
    const userInfo = inject("userInfo") as any;

    //查看购物车
    function toViewCart() {
      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          content: '<div>登录后才可查看购物车</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        router.push({name: "ShopCar"});
      }
    }



    function handleAddCart() {
      const formData = new FormData();

      formData.append("pid", props.pid);
      formData.append("tid", props.tid);
      formData.append("lotid", props.lotid);
      formData.append("tcid", props.tcid);
      formData.append("qty", "1");
      api
        .post("/M/Server/AddToCart", formData)
        .then((res): void => {
          Toast({
            title: res.data.msg,
            type: "success",
          });
        })
        .catch((err) => {
          Toast({
            title: err.msg || "加入购物车失败",
            type: "error",
          });
        });
    }

      function collectAction() {
        const formData = new FormData();
        formData.append("id", props.pid);
        formData.append("type", props.type);
        api
                .post("/M/Server/Collection", formData, {
                  params: {
                    validate: true,
                  },
                })
                .then((res) => {
                  if (res) {
                    Toast({
                      title: res.data.msg,
                      type: "success",
                    });
                    const collectImg = $('#collectImg').attr('src')
                    $("#collect img").prop(
                            "src",
                            collectImg
                    );
                  }
                })
                .catch((err) => {
                  Toast({
                    title: err.message || "收藏失败，请联系管理员",
                    type: "error",
                  });
                });
      }

    return {
      handleAddCart,
      collectAction,
      addShopCar,
      toViewCart,
      userInfo
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1.012px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}

footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-nav {
  position: relative;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}

.footer-li img {
  display: block;
  width: 20;
  height: 20px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -10px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #b9135a;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 11px;
}

footer .button a {
  color: #fff;
}

footer .button2{
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border:solid 1px #b9135a;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 11px;
}

footer .button2 a {
  color: #b9135a;
}

.buttonHide {
  visibility: hidden;
}
</style>
